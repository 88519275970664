<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Enterprise" style="width:100vw">
  <div id="a514f597">
    <div id="a59b4e5a">
      <div id="a5922301">
        标题
      </div>
      <el-input id="aad69c8f" v-model="inp_aad69c8f">
      </el-input>
    </div>
    <div id="a97de683">
      <div id="b9c90fb4">
        企业简介
      </div>
      <div id="b3dd42b3" ref="ref_b3dd42b3">
      </div>
    </div>
    <div id="a7443ea8">
      <el-button id="af5cb367" @click="clickaf5cb367()">
        保存
      </el-button>
      <el-button id="f116d8c3" @click="clickf116d8c3()">
        取消
      </el-button>
    </div>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_editor from "wangeditor"
import dal_rest from "@/api/restful.js"
let edt_ref_b3dd42b3 = null
export default{
  data(){
    return{
    inp_aad69c8f:'',
    ref_b3dd42b3:''
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
    edt_ref_b3dd42b3 = new dal_editor(this.$refs.ref_b3dd42b3)
    edt_ref_b3dd42b3.config.uploadImgMaxLength = 1
    edt_ref_b3dd42b3.config.zIndex = 1
    edt_ref_b3dd42b3.config.height = 118
    edt_ref_b3dd42b3.config.customUploadImg = (resultFiles, insertImgFn) => {
      let fd = new FormData();
      fd.append("file", resultFiles[0]);
      dal_rest.post("", fd, {
        headers: { "Content-Type": "multipart/form-data","keepAlive":true },
        baseURL: "/myupload",
      })
      .then(
        (res) => {
          console.log("上传成功", res);
          let imgurl = res.content.url;
          insertImgFn(imgurl)
        },
        (err) => {
          console.log("上传失败", err);
          this.$message("上传失败");
        })
    }
    edt_ref_b3dd42b3.create()
    edt_ref_b3dd42b3.txt.html(this.$route.query.articleContent)
  },
  computed:{
  },
  watch:{
  },
  methods:{
    async clickaf5cb367(){
    },
    async clickf116d8c3(){
    }
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a514f597{
  width:1649px;
  position:relative;
  padding-bottom:643px;
}
#a59b4e5a{
  width:588px;
  position:relative;
  padding-left:70px;
  padding-top:216px;
}
#a5922301{
  padding-top:13px;
  padding-bottom:13px;
  display:inline-block;
  vertical-align: middle;
}
#a5922301{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#a59b4e5a .el-input{
  width:436px;
  margin-left:126px;
  display:inline-block;
  vertical-align: middle;
}
#a97de683{
  width:952px;
  position:relative;
  margin-top:43px;
  padding-left:70px;
}
#b9c90fb4{
  padding-bottom:182px;
  display:inline-block;
  vertical-align: middle;
}
#b9c90fb4{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#b3dd42b3{
  width:800px;
  margin-left:100px;
  display:inline-block;
  vertical-align: middle;
}
#a7443ea8{
  width:344px;
  position:relative;
  margin-top:60px;
  padding-left:70px;
}
#af5cb367{
  width:142px;
  display:inline-block;
  vertical-align: middle;
}
#af5cb367{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#af5cb367{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#f116d8c3{
  width:142px;
  margin-left:60px;
  display:inline-block;
  vertical-align: middle;
}
#f116d8c3{
  background-color:rgb(153,153,153,1);
  border-radius:0px 0px 0px 0px;
}
#f116d8c3{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
/*在此注释下方添加自定义样式*/
</style>
